import React, { Fragment, useState } from "react";
import { array, number } from "prop-types";
import { api } from "../../../api";
import { Loader } from "../../../shared/Loader";
import { Pagination } from "../../../shared/Pagination";
import { RenderIf } from "../../../shared/RenderIf";
import { Table } from "../../../shared/Table";
import { useGetApi } from "../../../shared/useGetApi";
import { useTabCount } from "../../../shared/useTabCount";
import { useRecordCount } from "../../../shared/useRecordCount";

const Callbacks = ({ ownerId, tabState }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [data, loading] = useGetApi({
    get: api.owners.callbacks,
    id: ownerId,
    params: {
      callback: pageNumber,
    },
  });
  const count = useRecordCount(data);

  useTabCount({ collection: data }, tabState);

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Pagination
        label={"Reminders"}
        onPageChange={setPageNumber}
        recordCount={count}
        startPage={pageNumber}
      />
      <Table
        id="viewcallbacks"
        isBordered={true}
        isCondensed={true}
        isStriped={true}
      >
        <tbody>
          <tr>
            <td colSpan="8">
              <b>Active Reminders</b>
            </td>
          </tr>
          <tr>
            <th>
              <br />
              Status
            </th>
            <th>
              <br />
              Pet Name
            </th>
            <th>
              <br />
              Product Type
            </th>
            <th className="aligncenter">
              <br />
              Reminder Date
            </th>
            <th className="aligncenter">
              Vaccert
              <br />
              or Order #
            </th>
            <th className="aligncenter">
              Clinic/Order
              <br />
              Date
            </th>
            <th className="aligncenter">
              Reminder
              <br />
              Activity
            </th>
            <th className="aligncenter">
              Reminder
              <br />
              Sent
            </th>
          </tr>
          <RenderIf isTrue={count === 0}>
            <tr>
              <td>
                <p>No reminders</p>
              </td>
            </tr>
          </RenderIf>
          {data.map((callback) => (
            <tr key={`callback-${callback.id}`}>
              <td>
                <RenderIf isTrue={callback.isPastDue}>
                  <i>
                    <b>Past Due</b>
                  </i>
                </RenderIf>
                <RenderIf isTrue={!callback.isPastDue}>
                  <i>Pending</i>
                </RenderIf>
              </td>
              <td>
                <a href={callback.links.showPet}>{callback.petName}</a>
              </td>
              <td>{callback.productTypeName}</td>
              <td className="aligncenter">{callback.callbackAtFormatted}</td>
              <td className="aligncenter">
                <a href={callback.links.showSource}>{callback.sourceNumber}</a>
              </td>
              <td className="aligncenter">{callback.sourceDateFormatted}</td>
              <td className="aligncenter">
                <span
                  dangerouslySetInnerHTML={{ __html: callback.lastAction }}
                />
              </td>
              <td className="aligncenter">{callback.mailedAtFormatted}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

Callbacks.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
};

export default Callbacks;
